import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import { auth } from "./auth.module";
import { token } from "./token.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import modal from "./master.module";
import advocacy from "./advokasi.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    modal,
    advocacy,
    token
  },
  plugins: [createPersistedState()]
});
