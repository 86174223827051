import TokenService from "../token.service";

export const token = {
  namespaced: true,
  state: {
    // user: null,
    token: null,
    refresh_token: null,
    parsed_token: null,
    parsed_refresh_token: null,
    is_superadmin: false,
    attribute: {
      role: [],
      permissions: []
    }
  },
  actions: {
    getAccessToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (Date.now() >= state.parsed_token.exp * 1000) {
          // access token expired, get new access token if refresh token still valid
          if (Date.now() < state.parsed_refresh_token.exp * 1000) {
            // refresh token still valid
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + state.refresh_token
              }
            };
            fetch(
              `${process.env.VUE_APP_URL_LOCAL}/token/refresh`,
              requestOptions
              )
              .then(r => r.json())
              .then(data => {
                commit("setToken", data.access_token);
                resolve(data.access_token);
              })
              .catch(reason => {
                reject(reason);
              });
          } else {
            // logout user
            this.dispatch("auth/logout")
              .then(() => {
                location.reload(true);
              })
            // commit("auth/logout", null, { root: true }).then(() =>
            //   this.$router.push("/login")
            // );
            reject("Refresh token expired, please re-login");
          }
        } else {
          resolve(state.token);
        }
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("setLogout");
        resolve();
      });
    }
  },
  getters: {
    attribute: state => state.attribute,
    hasPermission: state => name => {
      let result = false;
      for (let i = 0; i < state.attribute.permissions.length; i++) {
        if (state.attribute.permissions[i].name === name) {
          result = true;
          break;
        }
      }
      return result;
    }
  },
  mutations: {
    LOGIN_SUCCESS(state, response) {
      state.token = response.token;
    },
    setLogout(state) {
      state.token = null;
      state.refresh_token = null;
      state.parsed_token = null;
      state.parsed_refresh_token = null;
      state.is_superadmin = false;
    },
    setToken(state, payload) {
      state.token = payload;
      TokenService.setToken(payload);
    },
    setRefreshToken(state, payload) {
      state.refresh_token = payload;
    },
    setParsedToken(state, payload) {
      state.parsed_token = payload;
    },
    setParsedRefreshToken(state, payload) {
      state.parsed_refresh_token = payload;
    },
    setIsSuperAdmin(state, payload) {
      state.is_superadmin = payload;
    },
    setAttribute(state, payload) {
      state.attribute = payload;
    }
  }
};
