import axios from "axios";

const state = {
  dataDocument: {},
  dataNomenklatur: {}
};

const mutations = {
  data_document(state, data) {
    state.dataDocument = data;
  },
  data_nomenklatur(state, data) {
    state.dataNomenklatur = data;
  }
};

const actions = {
  searchDocument({ commit }, itemsearch) {
    let baseURL =
      process.env.VUE_APP_URL_LOCAL + `/dokumentasi?nama=${itemsearch}`;
    return new Promise((resolve, reject) => {
      commit("data_document");
      axios({
        url: baseURL,
        method: "GET"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getListDirectorate({ commit }) {
    let baseURL = process.env.VUE_APP_URL_LOCAL + `/master/direktorat`;
    return new Promise((resolve, reject) => {
      commit("data_document");
      axios({
        url: baseURL,
        method: "GET"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchFilterProvStats({ commit }, itemsearch) {
    let baseURL =
      process.env.VUE_APP_URL_LOCAL +
      // `/kelembagaan-provinsi?nomenklatur=${itemsearch}`;
      `/kelembagaan-provinsi?q=${itemsearch}`;
    return new Promise((resolve, reject) => {
      commit("data_nomenklatur");
      axios({
        url: baseURL,
        method: "GET"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchFilterKabKotaStats({ commit }, itemsearch) {
    let baseURL =
      process.env.VUE_APP_URL_LOCAL +
      `/kelembagaan-kota?nomenklatur=${itemsearch}`;
    return new Promise((resolve, reject) => {
      commit("data_nomenklatur");
      axios({
        url: baseURL,
        method: "GET"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  state,
  actions,
  mutations
};
