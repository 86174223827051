import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },
        {
          path: "/advokasi",
          name: "advokasi",
          component: () => import("@/view/pages/admin/advokasi/Advokasi.vue"),
          children: [
            {
              path: "tabel",
              name: "TabelAdvokasi",
              component: () =>
                import("@/view/pages/admin/advokasi/TabelAdvokasi.vue"),
            },
            {
              path: "tambah",
              name: "TambahAdvokasi",
              component: () =>
                import("@/view/pages/admin/advokasi/TambahAdvokasi.vue"),
            },
          ],
        },
        {
          path: "/dropdown/:id",
          name: "DropdownDokumentasiList",
          component: () =>
            import(
              "@/view/pages/admin/dokumentasi/DropdownDokumentasiList.vue"
            ),
        },
        {
          path: "/dokumentasi",
          name: "dokumentasi",
          component: () =>
            import("@/view/pages/admin/dokumentasi/Dokumentasi.vue"),
          children: [
            {
              path: "list",
              name: "ListDokumentasi",
              component: () =>
                import("@/view/pages/admin/dokumentasi/ListDokumentasi.vue"),
            },
            {
              path: "tambah",
              name: "TambahDokumentasi",
              component: () =>
                import("@/view/pages/admin/dokumentasi/TambahDokumentasi.vue"),
            },
            {
              path: "detail",
              name: "DetailDokumentasi",
              component: () =>
                import("@/view/pages/admin/dokumentasi/DetailDokumentasi.vue"),
            },
            {
              path: "daftar",
              name: "ListDokumentasiCard",
              component: () =>
                import(
                  "@/view/pages/admin/dokumentasi/ListDokumentasiCard.vue"
                ),
            },
            {
              path: "daftarlist/:id",
              name: "DetailDokumentasiList",
              component: () =>
                import(
                  "@/view/pages/admin/dokumentasi/DetailDokumentasiList.vue"
                ),
            },
            {
              path: "tabel/:id",
              name: "TabelDokumentasi",
              component: () =>
                import("@/view/pages/admin/dokumentasi/TabelDokumentasi.vue"),
            },
          ],
        },
        {
          path: "/data",
          name: "CardGroup",
          component: () =>
            import("@/view/pages/admin/perundangan/CardGroup.vue"),
        },
        {
          path: "/profil",
          name: "Profil",
          component: () => import("@/view/pages/admin/profil/Profil.vue"),
        },
        {
          path: "/tabel",
          name: "TabelUndangUndang",
          component: () =>
            import("@/view/pages/admin/produk-hukum/TabelUndangUndang.vue"),
        },
        {
          path: "/form",
          name: "tambah",
          component: () =>
            import("@/view/pages/admin/produk-hukum/FormTambah.vue"),
        },
        {
          path: "/form/:jenisId",
          name: "tambahWithId",
          component: () =>
            import("@/view/pages/admin/produk-hukum/FormTambah.vue"),
        },
        {
          path: "/notifikasi",
          name: "Notifikasi",
          component: () =>
            import("@/view/pages/admin/notifikasi/Notifikasi.vue"),
        },
        {
          path: "/role",
          name: "role",
          component: () => import("@/view/pages/admin/role/UserManagement.vue"),
        },
        {
          path: "/statistik",
          name: "Statistik",
          component: () => import("@/view/pages/admin/statistik/Statistik.vue"),
          children: [
            {
              path: "provinsi",
              name: "KelembagaanProvinsi",
              component: () =>
                import("@/view/pages/admin/statistik/KelembagaanProvinsi.vue"),
            },
            {
              path: "tambah",
              name: "TambahProvinsi",
              component: () =>
                import("@/view/pages/admin/statistik/TambahProvinsi.vue"),
            },
            {
              path: "ubah/:id",
              name: "UbahProvinsi",
              component: () =>
                import("@/view/pages/admin/statistik/UbahProvinsi.vue"),
            },
            {
              path: "kabkota",
              name: "KelembagaanKabKota",
              component: () =>
                import("@/view/pages/admin/statistik/KelembagaanKabKota.vue"),
            },
          ],
        },
        {
          path: "/pengaturan",
          name: "Pengaturan",
          component: () =>
            import("@/view/pages/admin/pengaturan/Pengaturan.vue"),
        },
        {
          path: "/audit-trail",
          name: "AuditTrail",
          component: () => import("@/view/pages/admin/audit-trail/AuditTrail.vue"),
          children: [
            {
              path: "table",
              name: "AuditTrailTable",
              component: () => import("@/view/pages/admin/audit-trail/Log.vue"),
            },
            {
              path: "detail/:id",
              name: "AuditTrailDetail",
              component: () => import("@/view/pages/admin/audit-trail/LogDetail.vue"),
            },
          ]
        },
        {
          path: "/panduan",
          name: "Panduan",
          component: () => import("@/view/pages/panduan/Panduan.vue"),
          children: [
            {
              path: "web",
              name: "ManualWeb",
              component: () =>
                import("@/view/pages/panduan/ManualWeb.vue"),
            },
            {
              path: "android",
              name: "ManualAndroid",
              component: () =>
                import("@/view/pages/panduan/ManualAndroid.vue"),
            },
            {
              path: "ios",
              name: "ManualIos",
              component: () =>
                import("@/view/pages/panduan/ManualIos.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "/forgot-password/verify/:token",
      name: "ForgotPassword",
      component: () => import("@/view/pages/auth/ForgotPassword"),
      meta: {
        guest: true,
      },
      props: true,
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/sso-kominfo-callback",
      name: "SsoKominfoCallback",
      component: () => import("@/view/pages/sso/kominfoCallback.vue"),
      meta: {
        guest: true,
      },
    },
    {
      path: "/sso-polpum-callback",
      name: "SsoPolpumCallback",
      component: () => import("@/view/pages/sso/polpumCallback.vue"),
      meta: {
        guest: true,
      },
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    }
  ],
});
