import axios from "axios";

const state = {
  dataAdvocacy: {}
};

const mutations = {
  data_advocacy(state, data) {
    state.dataAdvocacy = data;
  }
};

const actions = {
  searchAdvocacy({ commit }, itemsearch) {
    let baseURL = process.env.VUE_APP_URL_LOCAL + `/advokasi?q=${itemsearch}`;
    return new Promise((resolve, reject) => {
      commit("data_advocacy");
      axios({
        url: baseURL,
        method: "GET"
      })
        .then(resp => {
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getListAdvocacy({ commit }) {
    let baseURL = process.env.VUE_APP_URL_LOCAL + `/advokasi`;
    return new Promise((resolve, reject) => {
      axios({
        url: baseURL,
        method: "GET"
      })
        .then(resp => {
          commit("data_advocacy");
          resolve(resp);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  state,
  actions,
  mutations
};
