import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
// import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import setupInterceptors from "@/core/services/setupInterceptors";
import { VueReCaptcha } from "vue-recaptcha-v3";

setupInterceptors(store);

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
// import Axios from "axios";

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "devextreme/dist/css/dx.light.css";
import "@/core/plugins/formvalidation";
import "./assets/css/custom.css";
import { setLabelRequired } from "@/core/appUtil/util";

// API service init
// ApiService.init();

// Remove this to disable mock API
// MockService.init();

/*Vue.prototype.$http = Axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      console.log("logged");
      return;
    }
    next("/login");
  } else {
    console.log("no login");
    next();
  }
});*/

router.beforeEach((to, from, next) => {
  const publicPages = [
    "login",
    "dashboard",
    "ForgotPassword",
    "ListDokumentasi",
    "DetailDokumentasi",
    "ListDokumentasiCard",
    "DetailDokumentasiList",
    "DropdownDokumentasiList",
    "SsoKominfoCallback",
    "SsoPolpumCallback",
  ];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }

  // Set label of required field
  setTimeout(() => {
    let requiredFields = document.querySelectorAll("[required=required]");
    setLabelRequired(requiredFields)
  }, 500);
});

// Initiate Vue Recaptcha
Vue.use(VueReCaptcha, { 
  siteKey: `${process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY}`,
  loaderOptions: {autoHideBadge: true}
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
